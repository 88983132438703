import IndivCondTesters from "../components/conditions";

const checkForConditions = (data, values, index = 0) => {
  // If we don't require the conditions, obviously pass them
  if (!data.conditionsRequired) {
    return true;
  }

  // If values are non-existent we fail the check
  if (!values) {
    return false;
  }

  const condition = data.conditions[index];
  const { field, feature, condType, type } = condition;
  const relevantValue = values[field || feature];

  return IndivCondTesters[condType || type](relevantValue, condition);
};

export default checkForConditions;
