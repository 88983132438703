import React from "react";
import PropTypes from "prop-types";

function Spinner({ message, loading, className }) {
  return (
    // Fragment is here because we only want to show spinner while loading
    // but also need to return something from this function.
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loading && (
        <div
          className={`flex items-center justify-center w-full ${
            className || ""
          }`}
        >
          <div className="bg-primary bg-opacity-90 animate-spin h-6 w-6 mr-3 rounded-md flex items-center justify-center">
            <div className="border-accent bg-white bg-opacity-90 h-1/2 w-1/2 rotate-45" />
          </div>

          {message}
        </div>
      )}
    </>
  );
}

Spinner.propTypes = {
  message: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

Spinner.defaultProps = {
  message: "Loading",
  loading: false,
  className: undefined,
};

export default Spinner;
