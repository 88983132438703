const BLOCK_PROCESSING_TYPE = {
  CUMULATIVE: "cumulative",
  PARALLEL: "parallel",
  INCREMENTAL: "incremental",
};

const LIMIT_TYPE = {
  FIXED: "fixed",
  FEATURE: "feature",
};

const STATUSES = {
  MATCHED: "matched",
  NOT_MATCHED: "not_matched",
  PARTIAL_MATCH: "partial_match",
};

const COND_TYPE = {
  EQUALS: "equal",
  NOT_EQUALS: "notEqual",
  NOT_EQUALS_HACKY_FIX: "not equal",
  MIN: "min",
  MAX: "max",
  BETWEEN: "between",
  MATCH_BOOKING: "matchBooking",
  ONE_OF: "oneOf",
  ONE_OF_HACKY_FIX: "one of",
  TIME_DAY: "time_day",
  TIME_BETWEEN: "time_between",
  DURATION_HOURS: "duration_hours",
};

export {
  COND_TYPE,
  STATUSES,
  BLOCK_PROCESSING_TYPE,
  LIMIT_TYPE,
};
