import { COND_TYPE } from "../lib/bookingLogic/params";

export const oneOfCondTest = (val, data) => {
  if (Array.isArray(val) && Array.isArray(data.value)) {
    return val.some((v) => data.value.includes(v));
  } else if (Array.isArray(data.value)) {
    return data.value.includes(val);
  } else {
    return data.value === val;
  }
};

export const betweenCondTest = (val, data) => data.min <= val && val <= data.max;

const notEqualsCondTest = (val, data) => val !== data.value;

const IndivCondTesters = {
  [COND_TYPE.EQUALS]: (val, data) => val === data.value,
  [COND_TYPE.NOT_EQUALS]: notEqualsCondTest,
  // When bookings are created they were created with a different constant for NOT EQUALS.
  // This is a hacky but working fix to make sure these bookings work till we can fix it in app
  // TODO: @chrisakroyd 13/06/2023
  [COND_TYPE.NOT_EQUALS_HACKY_FIX]: notEqualsCondTest,
  [COND_TYPE.MIN]: (val, data) => val >= data.value,
  [COND_TYPE.MAX]: (val, data) => val <= data.value,
  [COND_TYPE.BETWEEN]: betweenCondTest,
  [COND_TYPE.MATCH_BOOKING]: (val, data) => val === data.value,
  [COND_TYPE.ONE_OF]: oneOfCondTest,
  [COND_TYPE.ONE_OF_HACKY_FIX]: oneOfCondTest,
};

export default IndivCondTesters;
