import React, { useContext, useEffect, useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  Timestamp,
  arrayUnion,
  writeBatch,
} from "firebase/firestore";
import * as Sentry from "@sentry/nextjs";

import FieldComponent from "./FieldComponent";
import UserContext from "./UserContext";
import checkForConditions from "../methods/checkForConditions";
import Spinner from "./Spinner";
import { MEMBERS_COLLECTION } from "../constants/collections";

const ExtraDetails = ({
  orgId,
  label,
  hideSave,
  formId,
  eventData,
  profileId,
  color,
  textColor,
  existingDetails,
  excludeFieldIds,
  noDetailsExist,
  loading,
  noMaxHeight,
  onFinish,
  additionalQuestions = [],
}) => {
  const [schema, setSchema] = useState({});
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);
  const arrayFields = ["Email", "Postcode", "Full Name", "Phone", "tags"];
  const [fromDb, setFromDb] = useState({});
  const startTags = eventData?.tags || [];

  const [formValues, setFormValues] = useState({
    Email: user?.email,
    "Full Name": existingDetails?.["Full Name"]?.[0],
    tags: existingDetails?.tags
      ? [...new Set([...startTags, ...existingDetails.tags])]
      : startTags,
  });

  console.log({ formValues, noDetailsExist });

  useEffect(() => {
    if (!noDetailsExist) {
      setFormValues({});
      const { DEMOGRAPHICS = {}, ...fromTTS } = existingDetails;
      setFromDb({
        ...fromTTS,
        ...DEMOGRAPHICS,
      });
    }
  }, [existingDetails, noDetailsExist]);

  useEffect(() => {
    const arrayKeysToChange = ["Email", "Full Name", "Postcode", "Phone"];
    const cleanedUp = Object.keys(fromDb).reduce((obj, key) => {
      if (fromDb[key] instanceof Timestamp) {
        obj[key] = fromDb[key].toDate();
      } else if (
        arrayKeysToChange.includes(key) &&
        Array.isArray(fromDb[key])
      ) {
        obj[key] = fromDb[key][0];
      } else {
        obj[key] = fromDb[key];
      }
      return obj;
    }, {});

    setFormValues({
      ...cleanedUp,
      Email: user?.email,
      "Full Name": fromDb?.["Full Name"]?.[0] || "",
      tags: fromDb?.tags
        ? [...new Set([...startTags, ...fromDb.tags])]
        : startTags,
    });
  }, [fromDb]);

  const fetchSchema = async () => {
    const db = getFirestore();
    const schemasRef = collection(db, `Organisations/${orgId}/Schemas`);
    const eventDocRef = doc(schemasRef, "master");
    const result = (await getDoc(eventDocRef)).data();
    setSchema(result);
  };

  const [formData, setFormData] = useState({});
  const [formLoading, setFormLoading] = useState(true);
  const [formLoadingError, setFormLoadingError] = useState(null);

  const fetchForm = async () => {
    const db = getFirestore();
    try {
      const formsRef = collection(db, "Forms");
      const formDocRef = doc(formsRef, formId);
      const result = (await getDoc(formDocRef)).data();
      setFormLoading(false);
      setFormData(result);
      setFormLoadingError(null);
    } catch (err) {
      Sentry.captureException(err);
      setFormLoadingError(err);
      setFormLoading(false);
    }
  };

  useEffect(() => {
    if (orgId && formId) {
      fetchSchema();
      fetchForm();
    } else {
      setFormLoadingError({ message: "No registration form could be found" });
    }
  }, [orgId, formId]);

  const handleInputChange = (e) => {
    const { id, value, checked, type } = e.target;
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  const additionalAnswers = additionalQuestions.reduce((obj, question) => {
    obj[question.id] = formValues[question.id];
    return obj;
  }, {});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = formValues;
    // Only update the values that are in this form
    const toSave = Object.keys(data)
      ?.filter((key) => formData.questions[key] || key === "tags")
      ?.filter((key) => data[key])
      ?.reduce((obj, key) => {
        if (schema?.DEMOGRAPHICS?.[key]) {
          obj.DEMOGRAPHICS = obj.DEMOGRAPHICS
            ? { ...obj.DEMOGRAPHICS, [key]: data[key] }
            : { [key]: data[key] };
        } else if (arrayFields?.includes(key)) {
          obj[key] = Array.isArray(formValues[key])
            ? formValues[key]
            : [formValues[key]];
        } else {
          obj[key] = data[key];
        }
        return obj;
      }, {});

    const db = getFirestore();
    try {
      if (user?.uid) {
        if (existingDetails?._id) {
          const existingMemberRef = doc(
            db,
            MEMBERS_COLLECTION,
            existingDetails._id
          );

          const existingProfileRef = doc(
            db,
            `User/${user.uid}/Profiles`,
            existingDetails?.userProfileId || profileId || existingDetails?._id
          );

          const batch = writeBatch(db);

          batch.set(
            existingMemberRef,
            {
              ...toSave,
              User: user?.uid,
              updated: new Date(),
            },
            { merge: true }
          );
          // update the core profile
          const { managedBy, tags, _id, ...rest } = toSave;
          const { DEMOGRAPHICS = {}, ...restOfExisting } = rest;
          const demographicUpdateStrings = Object.keys(DEMOGRAPHICS).reduce(
            (obj, key) => {
              obj[`DEMOGRAPHICS.${key}`] = DEMOGRAPHICS[key];
              return obj;
            },
            {}
          );

          batch.update(
            existingProfileRef,
            {
              ...restOfExisting,
              ...demographicUpdateStrings,
              updated: new Date(),
              orgIds: arrayUnion(orgId),
            },
            { merge: true }
          );

          await batch.commit();

          onFinish(existingDetails?._id, additionalAnswers, {
            ...existingDetails,
            ...toSave,
          });
        } else {
          if (profileId || existingDetails?.sourceId) {
            toSave.userProfileId = profileId || existingDetails?.sourceId;
          }
          if (existingDetails?.funderMemberId) {
            toSave.funderMemberId = existingDetails?.funderMemberId;
          }

          const newDocRef = doc(collection(db, MEMBERS_COLLECTION));
          const newProfileRef = doc(
            db,
            `User/${user.uid}/Profiles`,
            profileId || existingDetails?.sourceId || newDocRef.id
          );

          const batch = writeBatch(db);

          batch.set(
            newDocRef,
            {
              DEMOGRAPHICS: {},
              ...toSave,
              User: user?.uid,
              managedBy: orgId,
              created: new Date(),
              updated: new Date(),
              userCreated: true,
            },
            { merge: true }
          );

          const { managedBy, tags, _id, ...rest } = toSave;

          batch.set(
            newProfileRef,
            {
              ...rest,
              updated: new Date(),
              orgIds: arrayUnion(orgId),
            },
            { merge: true }
          );

          await batch.commit();
          onFinish(newDocRef.id, additionalAnswers, {
            ...existingDetails,
            ...toSave,
          });
        }
      }
    } catch (err) {
      // TO DO - handle error here
      console.log({ err });
      setError(err);
    }
  };

  const relevantQuestions = formData.questions
    ? Object.keys(formData.questions)
        .sort(
          (a, b) => formData.questions[a].order - formData.questions[b].order
        )
        .filter((key) => key !== "Email" && key !== "tags")
        .filter(
          (key) =>
            !excludeFieldIds ||
            !excludeFieldIds.length ||
            !excludeFieldIds.includes(key)
        )
        .filter((key) =>
          checkForConditions(formData.questions[key], formValues)
        )
    : [];

  const lengthOfAdditionalQuestions = additionalQuestions?.length || 0;

  const allQuestionsLength =
    relevantQuestions.length + lengthOfAdditionalQuestions;

  return (
    <>
      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                There was an error with saving your data
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <ul role="list" className="list-disc pl-5 space-y-1">
                  <li>
                    Code: {error.code}. Message: {error.message}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {formLoading ? (
        <div className="flex items-center justify-center h-64 w-full">
          <Spinner loading />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div
            style={{ maxHeight: noMaxHeight ? "" : "calc(100vh - 260px)" }}
            className="overflow-y-auto space-y-3 p-4 rounded-lg border-gray-300 border bg-gray-50 mb-4 pb-4"
          >
            {formLoadingError && (
              <div className="rounded-md bg-red-50 p-4 border border-red-600">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      There was an error loading the details form.
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                      <ul role="list" className="list-disc pl-5 space-y-1">
                        <li>
                          Code: {formLoadingError.code}. Message:{" "}
                          {formLoadingError.message}
                        </li>
                        {formLoadingError.code === "unavailable" && (
                          <li>
                            If you still have internet connection, access might
                            be being blocked by a firewall.
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {formData?.questions &&
              (allQuestionsLength > 0 ? (
                relevantQuestions.map((key, index) =>
                  formData.questions[key].type === "divider" ? (
                    <div className="border-b border-greyClear text-xl font-medium pb-2">
                      <div
                        className={classNames({ hidden: index === 0 }, "h-4")}
                      />
                      {formData.questions[key].label || "Section"}
                    </div>
                  ) : (
                    <>
                      <label
                        key={key}
                        htmlFor={key}
                        className="block text-sm font-medium text-gray-700 whitespace-pre-wrap"
                      >
                        {formData.questions[key].label}
                        {formData.questions[key].required && (
                          <span className="text-red-500 leading-4 text-lg">
                            {" "}
                            *
                          </span>
                        )}
                        {!formData.questions[key].required && (
                          <span className="italic font-normal">
                            {" "}
                            - optional
                          </span>
                        )}
                      </label>
                      {Object.keys(formValues)?.length > 0 && (
                        <FieldComponent
                          {...formData.questions[key]}
                          id={key}
                          placeholder={formData.questions[key].name}
                          value={formValues?.[key]}
                          schemaObj={schema[key] || schema.DEMOGRAPHICS?.[key]}
                          onChange={handleInputChange}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-greyClear rounded-md max-w-lg"
                        />
                      )}
                    </>
                  )
                )
              ) : (
                <div className="text-center text-gray-600">
                  No extra details required. You can add your booking to your
                  basket below!
                </div>
              ))}
            {additionalQuestions.map((question) => (
              <>
                <label
                  key={question.id}
                  htmlFor={question.id}
                  className="block text-sm font-medium text-gray-700"
                >
                  {question.name}
                  <span className="italic font-normal">
                    {!question.required ? (
                      " - optional"
                    ) : (
                      <span className="text-red-500"> * </span>
                    )}
                  </span>
                </label>

                <FieldComponent
                  {...question}
                  id={question.id}
                  placeholder={question.name}
                  value={formValues?.[question.id]}
                  schemaObj={question}
                  onChange={handleInputChange}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-greyClear rounded-md max-w-lg"
                />
              </>
            ))}
          </div>
          {!hideSave && (
            <button
              disabled={loading}
              style={{
                backgroundColor: (!loading && color) || null,
                color: (!loading && textColor) || null,
              }}
              type="submit"
              className="disabled:bg-gray-200 text-primary disabled:cursor-not-allowed py-2 btn-primary w-full"
            >
              {label}
            </button>
          )}
        </form>
      )}
    </>
  );
};

ExtraDetails.propTypes = {
  orgId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  eventData: PropTypes.shape({}).isRequired,
  existingDetails: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  color: PropTypes.string,
  textColor: PropTypes.string,
  onFinish: PropTypes.func,
  label: PropTypes.string,
};

ExtraDetails.defaultProps = {
  label: "Save",
  existingDetails: undefined,
  color: null,
  textColor: null,
};

export default ExtraDetails;
